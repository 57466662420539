import React from 'react'
import './about.css'
import { about, asief, diwagar, praveen, ragul } from '../../constant/images'
import SvgHeart from '../../components/svg/Heart'
import SvgLock from '../../components/svg/Lock'
import SvgTeam from '../../components/svg/Team'
import SvgIdea from '../../components/svg/Idea'


const About = () => {
    return (
        <div>          
            <div className='abt-section-main'>
            <div className='abt-section1'>
                <div className='abt-section1-container1'>
                    <div className='abt-cont'>
                        <div className='abt-head-para'>
                            <h1>Our Jouney </h1>
                            <p >Founded in insert founding year, we set out to simplify business operations by creating an all-in-one platform for HR, scheduling, and communication. What began as a small idea has grown into a trusted solution for companies of all sizes. Despite challenges, our commitment to innovation drives us forward, with a clear vision to make work life simpler and more efficient for businesses worldwide.</p>
                        </div>
                    </div>
                </div>
                <div className='abt-section1-container2'>
                    <img src={about} alt="" />
                </div>
            </div>
            </div>
            <div className='abt-section2-main'>
                <div className='abt-section2'>
                    <h1>Our Foundation</h1>
                    <div className='abt-sec2'>
                        <div className='abt-sec2-con1'>
                            <div className='abt-con1-img-head'>
                                <SvgIdea/>
                                <p>Innovative <br /> Solutions</p>
                            </div>
                            <div className='abt-con1-para'>
                                <p>We deliver advanced solutions that simplify works</p>
                            </div>
                        </div>
                        <div className='abt-sec2-con1'>
                            <div className='abt-con1-img-head'>
                                <SvgHeart/>
                                <p>user-Centric</p>
                            </div>
                            <div className='abt-con1-para'>
                                <p>User first-tailored solutions and exceptional support.</p>
                            </div>
                        </div>
                        <div className='abt-sec2-con1'>
                            <div className='abt-con1-img-head'>
                                <SvgLock/>
                                <p>Transparency</p>
                            </div>
                            <div className='abt-con1-para'>
                                <p>We ensure honesty, transparency, and accountability in all actions.</p>
                            </div>
                        </div>
                        <div className='abt-sec2-con1'>
                            <div className='abt-con1-img-head'>
                               <SvgTeam/>
                                <p>Teamwork</p>
                            </div>
                            <div className='abt-con1-para'>
                                <p>We value teamwork and diverse perspectives for better solutions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <div className='abt-section3-main'>
                <div className='abt-sec3'>
                    <div className='abt-sec3-head-para'>
                        <h1>Our Team</h1>
                        <p>At weblings, we combine innovation and expertise to revolutionize business operations. Our diverse team collaborates to deliver impactful, cutting-edge solutions.</p>
                    </div>
                    <div className='abt-cont1-img-text-main'>
                        <div className='abt-cont1-img-text'>
                        <img src={asief} alt="" />
                        <div className='abt-cont1-text'>
                            <p className='abt-cont1-para'>Mohammed Asief</p>
                            <p className='abt-cont1-para2'>Designer</p>
                        </div>
                        </div>
                        <div className='abt-cont1-img-text'>
                            <img src={praveen} alt="" />                     
                        <div className='abt-cont1-text'>
                            <p className='abt-cont1-para'>Praveen</p>
                            <p className='abt-cont1-para2'> Developer</p>
                        </div>
                        </div>
                        <div className='abt-cont1-img-text'>
                        <img src={ragul} alt="" />
                        <div className='abt-cont1-text'>
                            <p className='abt-cont1-para'>Rathinavel</p>
                            <p className='abt-cont1-para2'>Developer</p>
                        </div>
                        </div>
                        <div className='abt-cont1-img-text'>
                        <img src={diwagar} alt="" />
                        <div className='abt-cont1-text'>
                            <p className='abt-cont1-para'>Diwagar</p>
                            <p className='abt-cont1-para2'>Developer</p>
                        </div>
                        </div>
                    </div>
                </div>
           </div>          
        </div>
    )
}

export default About;