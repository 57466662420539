import * as React from "react";
const SvgHeart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#595959"
      d="M33.1 6.945c-4.4-3-9.833-1.6-12.766 1.834-2.933-3.434-8.366-4.85-12.766-1.834-2.334 1.6-3.8 4.3-3.9 7.15-.234 6.467 5.5 11.65 14.25 19.6l.166.15a3.327 3.327 0 0 0 4.483-.016l.184-.167C31.5 25.729 37.218 20.545 37 14.079c-.1-2.834-1.567-5.534-3.9-7.134M20.5 31.212l-.166.167-.166-.167C12.234 24.029 7 19.279 7 14.462c0-3.333 2.5-5.833 5.833-5.833 2.567 0 5.067 1.65 5.95 3.933h3.117c.866-2.283 3.366-3.933 5.933-3.933 3.334 0 5.833 2.5 5.833 5.833 0 4.817-5.233 9.567-13.166 16.75"
    />
  </svg>
);
export default SvgHeart;
