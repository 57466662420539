import React from 'react'
import './feature.css'
import HomeNavbar from '../navbar/navbar'
import Footer from '../footer/footer'
import { layer, email, empty, hrms, calender } from '../../constant/images'


const Feature = () => {
    return (
        <div>
           
            <div className='section1'>
                <div className='section1-container1'>
                    <div className='cont'>
                    <div className='head-para'>
                        <h1>Complete Package </h1>
                        <h6>All-in-One, Ready to Run, No Hidden Costs.</h6>
                    </div>
                    <p className='con1-para'>Discover our all-in-one software solution to power your start-up's growth. From email to HR tools, get everything you need in a single, affordable package-no extra charges. Support your company's success with our comprehensive, streamlined suite.</p>
                    <div className='button-con'>
                        <button className='sec1-btn1'>Get started</button>
                        <button className='sec1-btn2'>Learn More</button>
                    </div>
                    </div>
                </div>
                <div className='section1-container2'>
                    <img src={layer} alt="" />
                </div>
            </div>
            <div className='section2'>
                <div className='section2-container1'>
                    <div className='sec2-head-para'>
                        <h1>Email <br /> Hosting</h1>
                        <p>create a domain of your choice for your business and set up emails for you user <br /> or employes, unique email provides you a superiority and authenticity for your <br /> business</p>
                    </div>
                <div className='section2-container2'>
                        <img src={email} alt="email" />
                    </div>
                </div>
            </div>
            <div className='section2'>
                <div className='section3-container1'>
                    <div className='sec3-head-para'>
                        <h1 className='sec3-head'>Calendar <br /> Integration </h1>
                        <p>organize your tasks and events with the format of yours, pin you <br /> meetings, events, tasks. and can be sorted</p>
                    </div>
                <div className='section2-container2'>
                        <img src={calender} alt="email" />
                    </div>
                </div>
            </div>
            <div className='section2'>
                <div className='section4-container1'>
                    <div className='sec4-head-para'>
                        <h1 className='sec4-head'>Zora <br /> HRMS</h1>
                        <p>employees are the backbone of the organisation ,treating them right <br /> matters to the organisation growth, our Zora provides the exact <br /> support for hiring and firing, we have extensive modules to manage <br /> hiring process, onboarding training & leaving the employee</p>
                    </div>
                <div className='section2-container2'>
                        <img src={hrms} alt="email" />
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Feature