import React from 'react'
import './footer.css'
import { subscribe } from '../../constant/images'

const Footer = () => {
  return (
    <footer>
        <div className='main-footer'>
            <div className='subscribe-container'>
                <h4>Subscribe for Exclusive <br /> Offers</h4>
                <div className='sub-input-para'>
                <input type="text" placeholder='Enter Your Email'/>
                <p className='sub-para'>we keep it secure and tight that's our promise</p>
                </div>
                <button className='footer-btn'>Subscribe</button>
            </div>
            <div className='footer-links-container'>
                <img src={subscribe} alt="" width={'100%'} />
                {/* <ul className='footer-links'>
                    <li className='link-title'>Links</li>
                    <li className='links'>How</li>
                    <li className='links'>Documentation</li>
                    <li className='links'>Join</li>
                    <li className='links'>SignUp</li>
                </ul>
                <ul className='footer-links'>
                    <li className='link-title'>Links</li>
                    <li className='links'>How</li>
                    <li className='links'>Documentation</li>
                    <li className='links'>Join</li>
                    <li className='links'>SignUp</li>
                </ul>
                <ul className='footer-links'>
                    <li className='link-title'>Apps</li>
                    <li className='links'>Google</li>
                    <li className='links'>Apple</li>
                </ul> */}
            </div>
        </div>
    </footer>
  )
}

export default Footer