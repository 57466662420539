import React from "react";
import "./contact.css";
import HomeNavbar from "../navbar/navbar";
import { map } from "../../constant/images";
import Footer from "../footer/footer";

const Contact = () => {
  return (
    <div>
     
      <div className="main-sec">
        <div className="cont-sec">
          <div className="cont-head-main">
            <div className="cont-head">
              <h1>Get In Touch</h1>
              <h6>
                {" "}
                Whether you have a question, need assistance, or want to
                schedule a demo, our team is here to help.
              </h6>
            </div>
          </div>
          <div className="cont-detail-main">
            <div className="cont-detail-address">
              <h5>Our Office</h5>
              <div className="cont-detail">
                <div className="cont-detail-info">
                  <p>
                    Address: <br /> Weblings{" "}
                  </p>
                  <p>
                    Door No:8, K.M Nagar, <br /> 3rd Street, Velliyankadu,
                  </p>
                  <p>Tirupur, Tamilnadu, 641604.</p>
                  <p>India</p>
                </div>
                <div>
                  <p>
                    Phone: <br /> 97895 13198
                  </p>
                </div>
                <div>
                  <p>
                    Email: <br /> pkgowthamit@gmail.com
                  </p>
                </div>
              </div>
              <div className="cont-det-head-img">
                <h5>Location Map</h5>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1030.6618979390794!2d77.35222260239145!3d11.08118816408997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1727780601175!5m2!1sen!2sin"
                  width="100%"
                  height="300"
                  style={{border:0}}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="cont-detail-input">
              <h5>Request a Call Back</h5>
              <p>
                Fill out the form below, and we'll get back to you as soon as
                possible.
              </p>
              <div className="lab-inp-main">
                <div className="lab-inp">
                  <label htmlFor="">Name</label>
                  <input type="text" placeholder="Text" />
                </div>
                <div className="lab-inp">
                  <label htmlFor="">Name</label>
                  <input type="text" placeholder="Text" />
                </div>
                <div className="lab-inp">
                  <label htmlFor="">Name</label>
                  <input type="text" placeholder="Text" />
                </div>
                <div className="lab-inp">
                  <label htmlFor="">Name</label>
                  <input type="text" placeholder="Text" />
                </div>
                <div className="lab-inp">
                  <label htmlFor="">Name</label>
                  <input type="text" placeholder="Text" />
                </div>
                <button>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default Contact;
