import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Contact from "./pages/contact";
import Feature from "./pages/features";
import About from "./pages/about/about";
import Layout from "./pages/layout";

const RouterPath = () => {
  return (
    <Router basename="/">
      <Routes>     
        <Route path="/" element={<Navigate to="/feature" />}  />
        <Route path="/" element={<Layout />}>
          <Route path="about" element={<About />} />
          <Route path="feature" element={<Feature />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RouterPath;
