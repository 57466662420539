import React, { useState } from 'react'
import './navbar.css'
import { Link } from 'react-router-dom'
import SvgLogo from '../../components/svg/Logo'
import SvgMenu from '../../components/svg/Menu'


const HomeNavbar = () => {

  const [nav, setNav] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(prev => !prev);

  return (
    <header>
        <nav>
          <SvgLogo/>
            <ul className='navbar-main'>
                <li className={`navbar-list ${nav === 'feature'? 'active' : ''}`} onClick={()=>setNav('feature')}><Link to='/feature' >Features</Link></li>
                <li className={`navbar-list ${nav === 'about'? 'active' : ''}`}  onClick={()=>setNav('about')}><Link to='/about'>About</Link></li>
                <li className={`navbar-list ${nav === 'contact'? 'active' : ''}`}  onClick={()=>setNav('contact')}><Link to='/contact' >Contact</Link></li>
            </ul>
            <div>
            <button>Try Now</button>
            <div className='menu-icon' onClick={toggle}>
              <SvgMenu/>
            </div>
            </div>
        </nav>
      {
        isOpen && 
        <div className='menu-bar'>
          <button>Try Now</button>
          <p className={`navbar-list ${nav === 'feature'? 'active' : ''}`}><Link to='/' >Features</Link></p>
          <p className={`navbar-list ${nav === 'about'? 'active' : ''}`} ><Link to='/about'>About</Link></p>
          <p className={`navbar-list ${nav === 'contact'? 'active' : ''}`}><Link to='/contact' >Contact</Link></p>
        </div>
      }
    </header>
  )
}

export default HomeNavbar