import React from "react";
import { Outlet } from 'react-router-dom'
import HomeNavbar from "../navbar/navbar";
import Footer from "../footer/footer";


function Layout() {
  return (
    <>
      <HomeNavbar/>
      <div>
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default Layout;
